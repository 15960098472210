<template>
  <MainCard id="functionary-card">
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="(tabItem,index) in tabs"
          :key="tabItem.title"
          @click="reRenderComponent(index)"
        >
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :eager="true">
          <functionary-employee
            :key="componentKey"
          ></functionary-employee>
        </v-tab-item>
        <v-tab-item>
          <functionary-teacher :key="componentKey1"></functionary-teacher>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import FunctionaryEmployee from './FunctionaryEmployee.vue'
import FunctionaryTeacher from './FunctionaryTeacher.vue'

export default {
  name: 'Functionary',
  components: {
    FunctionaryEmployee,
    FunctionaryTeacher,
    MainCard,
  },
  props: {},
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Pegawai' }, { title: 'Guru' }],
      componentKey: 0,
      componentKey1: 0,
      valueCheck: false,
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey1 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
